import { provideNoopAnimations } from '@angular/platform-browser/animations';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, withInterceptorsFromDi, provideHttpClient } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { importProvidersFrom } from '@angular/core';

import { RestClientModule as GenerateApiModule, RestClientConfig as GenerateConfiguration } from 'src/api-client/generate';
import { RestClientModule as AvatarApiModule, RestClientConfig as AvatarConfiguration } from 'src/api-client/avatar';
import { RestClientModule as ProjectApiModule, RestClientConfig as ProjectConfiguration } from 'src/api-client/project';
import { RestClientModule as AssetApiModule, RestClientConfig as AssetConfiguration } from 'src/api-client/asset';
import { RestClientModule as AccountApiModule, RestClientConfig as AccountConfiguration } from 'src/api-client/account';

import { env } from 'src/environments/environment';

import { AppComponent } from './app/app.component';
import { AuthInterceptor } from './app/auth.interceptor';
import { UserStateService } from './app/auth/user-state.service';
import { APP_ROUTES } from './app/app-routes';
import { CdnInterceptor } from './app/cdn.interceptor';

import { provideLottieOptions } from 'ngx-lottie';
import { TimeagoModule } from 'ngx-timeago';

// Prime subdomains on load
if (env.preloadUrls.length) {
  const preloader = document.createElement('div');
  const spinner = document.createElement('div');
  spinner.classList.add('spinner');
  preloader.classList.add('preloader');
  preloader.appendChild(spinner);
  const urls = env.preloadUrls;
  let loaded = 0;
  for (const el of urls) {
    const fr = document.createElement('iframe');
    fr.src = el;
    fr.height = '1px';
    fr.width = '1px';
    fr.classList.add('preload');
    document.body.appendChild(fr);
    fr.onload = (): void => {
      loaded += 1;
      fr.remove();
      if (loaded === urls.length) {
        preloader.remove();
      }
    };
  }
  setTimeout(() => {
    if (loaded < urls.length) {
      document.body.appendChild(preloader);
    }
  }, 250);
}

bootstrapApplication(AppComponent, {
  providers: [
    provideLottieOptions({ player: () => import('lottie-web') }),
    importProvidersFrom(
      RouterModule.forRoot(APP_ROUTES),
      AccountApiModule.forRoot(() => new AccountConfiguration({
        baseUrl: env.accountApiUrl,
        timeout: env.apiDefaultTimeout,
        withCredentials: true,
      })),
      AssetApiModule.forRoot(() => new AssetConfiguration({
        baseUrl: env.assetApiUrl,
        // timeout: 30000,
        withCredentials: true,
      })),
      ProjectApiModule.forRoot(() => new ProjectConfiguration({
        baseUrl: env.projectApiUrl,
        timeout: env.apiDefaultTimeout,
        withCredentials: true,
      })),
      AvatarApiModule.forRoot(() => new AvatarConfiguration({
        baseUrl: env.avatarApiUrl,
        timeout: env.apiDefaultTimeout,
        withCredentials: true,
      })),
      GenerateApiModule.forRoot(() => new GenerateConfiguration({
        baseUrl: env.generateApiUrl,
        timeout: env.apiDefaultTimeout,
        withCredentials: true,
      })),
      BrowserModule,
      ReactiveFormsModule,
      TimeagoModule.forRoot(),
    ),
    {
      provide: HTTP_INTERCEPTORS,
      useFactory: (userState: UserStateService): AuthInterceptor => new AuthInterceptor(userState),
      multi: true,
      deps: [UserStateService]
    },
    ...(env.env !== 'local' ? [] : [
      {
        provide: HTTP_INTERCEPTORS,
        useFactory: (): CdnInterceptor => new CdnInterceptor(),
        multi: true,
      }]),
    provideHttpClient(withInterceptorsFromDi()),
    provideNoopAnimations(),

  ]
})
  .catch(err => console.error(err));