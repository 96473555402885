import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';
import { UserStateService } from '../user-state.service';

@Component({
  selector: 'app-login-complete',
  templateUrl: './login-complete.component.html',
  styleUrls: ['./login-complete.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true
})
export class LoginCompleteComponent {


  constructor(private router: Router, private state: UserStateService) { }

  ngOnInit(): void {
    if (window.location.search.includes('close')) {
      window.close();
      return;
    } else {
      this.router.navigateByUrl(this.state.readLoginRedirect() ?? '/');
    }
  }
}
