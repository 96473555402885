import { map, of } from 'rxjs';

import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot, createUrlTreeFromSnapshot } from '@angular/router';

import { UserStateService } from './user-state.service';
import { User } from 'src/api-client/account';
import { FeatureFlagService } from '../common/feature-flag.service';
let loaded = false;

type UserPredicate = (u: User) => boolean;

const permChecker = (perms: string[]): UserPredicate => (user: User): boolean => (perms.length === 0 || !!perms.find(x => user.permissions?.includes(x)));

const auth = (predicate: UserPredicate) => (route: ActivatedRouteSnapshot, routeState: RouterStateSnapshot) => {
  const state = inject(UserStateService);
  const featureFlag = inject(FeatureFlagService);
  const user = state.getUserNow();
  if (!user || !loaded) {
    loaded = true;
    return state.refreshUser()
      .pipe(map(val => {
        if (routeState.url.includes('close-popup')) {
          return;
        }
        const isMobileDevice = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(window.navigator.userAgent);
        if (isMobileDevice && !routeState.url.includes('mobile-landing')) {
          return createUrlTreeFromSnapshot(route, ['/', 'mobile-landing']);
        }
        if (val) {
          if (!val?.onboarding && !routeState.url.includes('onboarding')) {
            const date = featureFlag.flagSource.evalFeature('onboarding.startdate');
            // if your user was created after the onboarding start date, redirect them to onboarding;
            if (!date.value || new Date(val.createdDate!).getTime() > new Date(date.value).getTime()) {
              state.trackLoginRedirect();
              return createUrlTreeFromSnapshot(route, ['/', 'onboarding', '1']);
            }
          }
          return predicate(val);
        } else {
          state.trackLoginRedirect();
          return createUrlTreeFromSnapshot(route, ['/', 'login']);
        }
      }));
  } else {
    return of(predicate(user));
  }
};

export const authGuardWithPermissions = (...perms: string[]) => auth(permChecker(perms));
export const authGuard = authGuardWithPermissions();
