import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-index-redirect',
  standalone: true,
  imports: [],
  template: '',
  styles: ''
})
export class IndexRedirectComponent {
  constructor(router: Router) {
    router.navigateByUrl(router.url.replace(/\/index([.]html)?/g, ''));
  }
}
