<div class="wrapper">
  <div class="wrapper-inner">
    <div class="container-form">
      @if (!checkEmail) {
      <div>

        @if(message | async; as msg) {
        <app-toast type="error" [title]="msg" class="inline"></app-toast>
        }

        <h1>
          Welcome to Pipio
        </h1>
        <p>Time to get creating!</p>
        <button (click)="loginGoogle()" class="login google" [disabled]="loggingIn">
          @if (loggingIn === 'google') { <app-spinner></app-spinner> }
          @else { <img alt="{{callToAction}} With Google" src="assets/login/google-icon.png" /> }
          <span>{{callToAction}} with Google</span>
        </button>
        @if(!isProd()) {
        <button (click)="loginGoogleWithNewUser()" class="login google" [disabled]="loggingIn">
          @if (loggingIn === 'google-new') { <app-spinner></app-spinner> }
          @else { <img alt="{{callToAction}} With Google" src="assets/login/google-icon.png" />}
          <span>{{callToAction}} as New User with Google</span>
        </button>
        }
        <button (click)="loginFacebook()" class="login facebook" [disabled]="loggingIn">
          @if (loggingIn === 'facebook') { <app-spinner></app-spinner> }
          @else { <img alt="{{callToAction}} With Facebook" src="assets/login/facebook-icon.png" /> }
          <span>{{callToAction}} with Facebook</span>
        </button>
        <div class="divider">
          <hr />
          <span class="text">OR</span>
          <hr />
        </div>
      </div>
      <form [formGroup]="loginForm" (ngSubmit)="onSubmit($event)">
        <div class="input-group">
          <input formControlName="email" type="email" id="email"
            placeholder="Enter your email address" />
          @if (loginForm.invalid && (loginForm.dirty || loginForm.touched)) {
          <span class="form-errors">
            @if (loginForm.controls['email']?.errors?.['email']) {
            <span
              class="error">
              <i class="icon">error</i> Invalid email address
            </span>
            }
            @if (loginForm.controls['email']?.errors?.['required']) {
            <span
              class="error">
              <i class="icon">error</i> Email address is required
            </span>
            }
          </span>
          }
        </div>
        <button class="login email" type="submit">
          <span> {{callToAction}} with Email </span>
        </button>
      </form>
      <div class="info-text">
        <p>By continuing, you agree to our <a href="{{baseUrl}}/terms.html" target="_blank">Terms</a> and <a
            href="{{baseUrl}}/privacy-policy.html" target="_blank">Privacy Policy</a></p>
      </div>
      } @else {
      <div class="email-waiting">
        <span class="magic-wand">🪄</span>
        <h1 class="display-heading-1">Magic link sent!</h1>
        <h2>We just sent an email to {{loginForm.get('email')?.value}}. It contains a magic link that will sign you in
          real quick!</h2>
        <app-spinner></app-spinner>
        <a class="action basic primary" (click)="cancel()">Back to sign in</a>
      </div>
      }
    </div>
  </div>
</div>
<div class="side-graphic">
  <img src="/assets/logo/logo-outline-black.svg">
</div>