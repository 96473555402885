import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export const cdnToRelative = <T>(a: T): T => {
  if (a === null || a === undefined) {
    return a;
  } else if (Array.isArray(a)) {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return a.map(cdnToRelative) as unknown as T;
  } else if (typeof a === 'object') {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return Object.fromEntries(
      Object.entries(a).map(([k, v]) => [k, cdnToRelative(v)])) as T;
  } else if (typeof a === 'string' && a.startsWith('https://cdn.')) {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return new URL(a).pathname.replace(/^[/]/, '') as unknown as T;
  } else {
    return a;
  }
};


@Injectable()
export class CdnInterceptor implements HttpInterceptor {

  constructor() { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      map(ev => {
        if (ev instanceof HttpResponse) {
          ev = ev.clone({ body: cdnToRelative(ev.body) });
        }
        return ev;
      })
    );
  }
}

