import { ChangeDetectionStrategy, Component } from '@angular/core';

import { UserStateService } from '../user-state.service';

@Component({
    selector: 'app-logout',
    templateUrl: './logout.component.html',
    styleUrls: ['./logout.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true
})
export class LogoutComponent {
  constructor(userState: UserStateService) {
    userState.logout();
  }
}
