import { Injectable } from '@angular/core';
import { Observable, of, mergeMap, shareReplay } from 'rxjs';

import { MarketingInfo } from 'src/api-client/account';

type RewardfulWindow = typeof window & {
  _rwq?: string;
  rewardful?: ((...args: unknown[]) => void) & { q?: unknown[] };
  Rewardful?: {
    referral: string;
    affiliate: { id: string, token: string };
    coupon: { id: string };
    campaign: { id: string };
  };
};

@Injectable({ providedIn: 'root' })
export class ReferralService {

  #marketingInfo?: Observable<MarketingInfo | undefined>;

  #getInfo(): Promise<MarketingInfo | undefined> {
    return new Promise<MarketingInfo | undefined>(resolve => {
      const w: RewardfulWindow = window;
      if (w.rewardful) {
        w.rewardful('ready', () => {
          if (w.Rewardful?.referral) {
            resolve({
              campaignType: 'recurring',
              referralId: w.Rewardful.referral,
              affiliateId: w.Rewardful.affiliate.id,
              campaignId: w.Rewardful.campaign.id,
              affiliateToken: w.Rewardful.affiliate.token,
              couponId: w.Rewardful.coupon.id
            });
          } else {
            resolve(undefined);
          }
        });
      } else {
        resolve(undefined);
      }
    });
  }

  getInfo(): Observable<MarketingInfo | undefined> {
    return this.#marketingInfo ??= of(undefined)
      .pipe(
        mergeMap(() => Promise.race([this.#getInfo(), new Promise<undefined>(r => setTimeout(r, 250))])),
        shareReplay(1)
      );
  }
}