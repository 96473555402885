import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { env } from 'src/environments/environment';

@Component({
    selector: 'app-close-popup',
    templateUrl: './close-popup.component.html',
    styleUrls: ['./close-popup.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true
})
export class ClosePopupComponent implements OnInit {
  ngOnInit(): void {
    if (window.location.search.includes('now')) {
      window.close();
      return;
    }
    console.log('Closing!', !!window.opener.parent);
    window.opener.parent?.postMessage({ complete: true }, {
      targetOrigin: env.baseUrl
    });
  }
}
