import { Routes } from '@angular/router';

import { LoginComponent } from './auth/login/login.component';
import { LogoutComponent } from './auth/logout/logout.component';
import { authGuard, authGuardWithPermissions } from './auth/auth.guard';
import { unauthGuard } from './auth/unauth.guard';
import { ClosePopupComponent } from './auth/close-popup/close-popup.component';
import { LoginCompleteComponent } from './auth/login-complete/login-complete.component';
import { projectSaveGuard } from './project/project-save.guard';
import { IndexRedirectComponent } from './auth/index-redirect/index-redirect.component';

export const APP_ROUTES: Routes = [
  // Entrypoint support
  ...['login', 'sign-up', 'signup'].flatMap(
    (x) =>
      [
        {
          path: `${x}/index.html`,
          pathMatch: 'full',
          component: IndexRedirectComponent,
        },
        {
          path: `${x}/index`,
          pathMatch: 'full',
          component: IndexRedirectComponent,
        },
      ] as const
  ),
  {
    path: 'login',
    pathMatch: 'full',
    component: LoginComponent,
    canActivate: [unauthGuard],
    data: { title: 'Login' },
  },
  {
    path: 'login/complete',
    pathMatch: 'full',
    component: LoginCompleteComponent,
    canActivate: [authGuard],
    data: { title: 'Login Complete' },
  },
  {
    path: 'custom-avatar-flow',
    pathMatch: 'full',
    loadComponent: () => import('./custom-avatar/intake-flow/intake-flow.component').then(m => m.IntakeFlowComponent),
    canActivate: [authGuard,],
    data: { title: 'Create Custom Avatar' },
  },
  {
    path: 'onboarding/:version',
    pathMatch: 'full',
    loadComponent: () => import('./onboarding/onboarding/onboarding.component').then(m => m.OnboardingComponent),
    canActivate: [authGuard],
    data: { title: 'Onboarding' },
  },
  {
    path: 'sign-up',
    component: LoginComponent,
    pathMatch: 'full',
    canActivate: [unauthGuard],
    data: { title: 'Signup' },
  },
  {
    path: 'logout',
    component: LogoutComponent,
    canActivate: [authGuard],
    data: { title: 'Logout' },
  },
  {
    path: 'close-popup',
    pathMatch: 'full',
    component: ClosePopupComponent,
    data: { title: 'Popup Complete' },
  },
  {
    path: 'localization-intake',
    pathMatch: 'full',
    loadComponent: () => import('./project/localization-intake-flow/localization-intake-flow.component').then(m => m.LocalizationIntakeFlowComponent),
    canActivate: [authGuard],
    data: { title: 'Dubbing Intake' },
  },
  {
    path: 'localization',
    canActivateChild: [authGuard,],
    pathMatch: 'prefix',
    children: [
      {
        path: ':id',
        canActivate: [authGuard,],
        canDeactivate: [projectSaveGuard],
        providers: [],
        data: { title: 'Localization Workspace' },
        loadComponent: () =>
          import('./project/localization/localization.component').then((m) => m.LocalizationComponent),
      },
    ],
  },
  {
    path: 'project',
    canActivateChild: [authGuard,],
    pathMatch: 'prefix',
    children: [
      {
        path: ':id',
        canActivate: [authGuard,],
        canDeactivate: [projectSaveGuard],
        providers: [],
        data: { title: 'Project Workspace' },
        loadComponent: () =>
          import('./project/project.component').then((m) => m.ProjectComponent),
      },
    ],
  },
  {
    path: 'creator-studio',
    canActivate: [authGuard,],
    pathMatch: 'prefix',
    loadComponent: () => import('./common/creator-studio/creator-studio.component').then(m => m.CreatorStudioComponent),
  },
  {
    path: 'settings',
    pathMatch: 'prefix',
    canActivate: [authGuard,],
    canActivateChild: [authGuard,],
    loadComponent: () => import('./settings/settings.component').then((m) => m.SettingsComponent),
    children: [
      {
        path: 'profile',
        loadComponent: () =>
          import('./settings/profile/profile.component').then(
            (m) => m.ProfileComponent
          ),
        pathMatch: 'full',
        data: { title: 'User Profile' },
      },
      {
        path: 'billing',
        loadComponent: () =>
          import('./settings/billing/billing.component').then(
            (m) => m.BillingComponent
          ),
        pathMatch: 'full',
        data: { title: 'Billing Details' },
      },
      {
        path: 'api-keys',
        loadComponent: () =>
          import('./settings/api-keys/api-keys.component').then(
            (m) => m.ApiKeysComponent
          ),
        pathMatch: 'full',
        data: { title: 'API Management' },
      },
      {
        path: '',
        pathMatch: 'full',
        redirectTo: './profile',
      },
    ],
  },
  {
    path: 'dashboard',
    canActivate: [authGuard],
    pathMatch: 'prefix',
    loadComponent: () =>
      import('./dashboard/dashboard.component').then(
        (m) => m.DashboardComponent
      ),
    data: { title: 'Project Dashboard' },
    children: [
      {
        path: '',
        pathMatch: 'full',
        loadComponent: () =>
          import('./dashboard/dashboard-redirect.component').then(
            (m) => m.DashboardRedirectComponent
          ),
      },
      {
        path: 'projects',
        loadComponent: () =>
          import(
            './dashboard/project-dashboard/project-dashboard.component'
          ).then((m) => m.ProjectDashboardComponent),
        pathMatch: 'full',
        data: { title: 'Project Dashboard' },
      },
      {
        path: 'actors',
        loadComponent: () =>
          import('./dashboard/actor-dashboard/actor-dashboard.component').then(
            (m) => m.ActorDashboardComponent
          ),
        pathMatch: 'full',
        data: { title: 'Actor Dashboard' },
      },
      {
        path: 'voices',
        loadComponent: () =>
          import('./dashboard/voice-dashboard/voice-dashboard.component').then(
            (m) => m.VoiceDashboardComponent
          ),
        pathMatch: 'full',
        data: { title: 'Voice Dashboard' },
      },
      {
        path: 'localization',
        loadComponent: () =>
          import(
            './dashboard/localization-dashboard/localization-dashboard.component'
          ).then((m) => m.LocalizationDashboardComponent),
        pathMatch: 'full',
        data: { title: 'Dubbing Dashboard' },
      },
      {
        path: 'workflow',
        pathMatch: 'full',
        redirectTo: 'workflow/custom-avatar',
      },
      {
        path: 'workflow/custom-avatar',
        canActivate: [authGuardWithPermissions('support', 'admin')],
        loadComponent: () =>
          import(
            './workflow/custom-avatar-dashboard/custom-avatar-dashboard.component'
          ).then((m) => m.CustomAvatarDashboardComponent),
        pathMatch: 'full',
        data: { title: 'Custom Avatar Dashboard' },
      },
      {
        path: 'workflow/localization',
        canActivate: [authGuardWithPermissions('support', 'admin')],
        loadComponent: () =>
          import(
            './workflow/localization-dashboard/localization-dashboard.component'
          ).then((m) => m.LocalizationWorkflowDashboardComponent),
        pathMatch: 'full',
        data: { title: 'Localization Dashboard' },
      },
    ],
  },
  {
    path: 'mobile-landing',
    loadComponent: () => import('./dashboard/small-device-dialog/small-device-dialog.component').then(m => m.SmallDeviceDialogComponent),
  },
  {
    path: '**',
    redirectTo: 'dashboard',
  },
];
