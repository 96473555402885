import { CanDeactivateFn, UrlTree } from '@angular/router';
import { map, Observable, of, take } from 'rxjs';

export const projectSaveGuard: CanDeactivateFn<unknown> = (component: unknown): Observable<UrlTree | boolean> => {
  if (component && typeof component === 'object' && 'saveIfPending' in component && typeof component.saveIfPending === 'function') {
    return component.saveIfPending().pipe(
      take(1),
      map(() => true)
    );
  } else {
    return of(true);
  }
};