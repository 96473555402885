
import { ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AsyncPipe } from '@angular/common';
import { Observable, Subject, filter, map, mergeMap, take } from 'rxjs';

import { AuthService, MarketingInfo } from 'src/api-client/account';
import { ReferralService } from 'src/app/common/referral.service';
import { RemoteChangeService } from 'src/app/common/remote-change.service';
import { env } from 'src/environments/environment';
import { SpinnerComponent } from 'src/app/common/basic/spinner/spinner.component';
import { AnalyticsService } from 'src/app/common/analytics.service';
import { ToastComponent } from 'src/app/common/notify/toast/toast.component';

type LoginForm = {
  email: string;
  marketing?: string;
};

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ReactiveFormsModule, SpinnerComponent, AsyncPipe, ToastComponent]
})
export class LoginComponent implements OnDestroy, OnInit {
  destroy$ = new Subject<boolean>();

  loginForm: FormGroup<{
    email: FormControl;
    marketing: FormControl;
  }>;
  showEmail: boolean = false;
  checkEmail: boolean = false;

  baseUrl = env.websiteUrl;

  isSignup = false;

  message: Observable<string> | undefined = undefined;

  loggingIn?: string;

  constructor(
    private route: ActivatedRoute,
    private referral: ReferralService,
    private analytics: AnalyticsService,
    public auth: AuthService,
    public change: RemoteChangeService,
    public changeRef: ChangeDetectorRef,
    private router: Router
  ) {

    this.isSignup = router.url.includes('sign-up');

    this.loginForm = new FormGroup({
      email: new FormControl('', { nonNullable: true, validators: [Validators.required, Validators.email] }),
      marketing: new FormControl(''),
    }, {
      updateOn: 'submit'
    });

    this.message = route.queryParamMap.pipe(
      map(x => x.get('error')),
      filter((x): x is Exclude<typeof x, null> => !!x)
    );
  }

  get callToAction(): string {
    return this.isSignup ? 'Sign Up' : 'Continue';
  }

  ngOnInit(): void {
    this.route.queryParamMap
      .pipe(
        map(v => v.get('email')),
        filter(v => !!v),
        map(v => v?.replace(/ /g, '+')),
        take(1),
      ).subscribe(email => {
        this.loginForm.patchValue({ email });
        this.onSubmit();
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  login(name: string, marketing?: Partial<MarketingInfo>): void {
    this.referral.getInfo().subscribe(info => {
      const url = new URL(`${env.accountApiUrl}/auth/login/${name}`);
      if (info || marketing) {
        url.search = new URLSearchParams([['marketing', JSON.stringify({
          ...info,
          ...env.prod ? {} : marketing
        })]]).toString();
      }
      document.location = url.toString();
    });
  }

  loginGoogle(): void {
    if (this.isSignup) {
      this.analytics.trackProductEvent('signup_started', { signup_mode: 'Google' });
    }
    this.loggingIn = 'google';
    return this.login('google');
  }

  loginGoogleWithNewUser(): void {
    if (this.isSignup) {
      this.analytics.trackProductEvent('signup_started', { signup_mode: 'Google' });
    }
    this.loggingIn = 'google-new';
    return this.login('google', { campaignId: 'unique' });
  }

  loginFacebook(): void {
    if (this.isSignup) {
      this.analytics.trackProductEvent('signup_started', { signup_mode: 'Facebook' });
    }
    this.loggingIn = 'facebook';
    return this.login('facebook');
  }

  loginEmail(): void {
    this.showEmail = true;
  }

  isProd(): boolean {
    return env.prod;
  }

  onSubmit($event?: Event): void {
    $event?.preventDefault();

    if (this.loginForm.invalid) {
      return;
    }

    if (this.isSignup) {
      this.analytics.trackProductEvent('signup_started', { signup_mode: 'Email' });
    }

    this.referral.getInfo().pipe(
      mergeMap(info => {
        if (info) {
          this.loginForm.patchValue({ marketing: JSON.stringify(info) });
        }
        // Trigger
        this.checkEmail = true;

        this.changeRef.markForCheck();
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        return this.auth.sendLoginEmail(this.loginForm.value as LoginForm);
      }),
      mergeMap(({ keyId }) => this.change.listenLogin(keyId)),
      take(1),
      map(link => {
        window.focus();
        if (env.env === 'local' && link.includes('pipio.ai')) {
          const iframe = document.createElement('iframe');
          iframe.src = link.replace(/^.*pipio.ai/, env.accountApiUrl);
          document.body.appendChild(iframe);
          iframe.addEventListener('load', () => {
            window.location.reload();
          });
        } else {
          window.location.replace(link);
        }
      })
    ).subscribe();
  }

  cancel(): void {
    this.checkEmail = false;
    this.loginForm.reset();
    this.changeRef.markForCheck();
  }
}
