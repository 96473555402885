/// <reference path="./json-typings.d.ts" />

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, filter, map, merge, mergeMap, of } from 'rxjs';

import current from 'src/release.json';
import { RemoteChangeService } from './remote-change.service';

type Release = { version: string, date: string, dateMs: number };

@Injectable({ providedIn: 'root' })
export class ReleaseDetectService {

  watch$: Observable<{ current: Release, next: Release }>;

  constructor(http: HttpClient, change: RemoteChangeService) {
    this.watch$ = merge(of(undefined), change.listenRelease('ui'))
      .pipe(
        mergeMap(() => http.get<Release>(`/release.json?q=${Date.now()}`, { headers: { Pragma: 'no-cache' } })),
        filter(x => x.dateMs > current.dateMs),
        map(next => ({ next, current }))
      );
  }

  async reloadApp(): Promise<void> {
    await Promise.all([
      fetch(window.location.toString(), { headers: { Pragma: 'no-cache' } }),
      fetch('/index.html', { headers: { Pragma: 'no-cache' } })
    ])
      .then(() => { window.location.reload(); });
  }
}
