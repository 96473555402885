import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, ElementRef, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute, NavigationEnd, Router, RouterOutlet } from '@angular/router';

import { distinctUntilChanged, filter, map, merge, mergeMap, of, take, tap } from 'rxjs';

import { User } from 'src/api-client/account';
import { ReleaseDetectService } from 'src/app/common/release-detect.service';
import { AnalyticsService } from 'src/app/common/analytics.service';

import { UserStateService } from './auth/user-state.service';
import { MainScrollerService } from './common/main-scroller-service/main-scroller-service.component';
import { DynamicDialogService } from './common/notify/dynamic-dialog.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [RouterOutlet]
})
export class AppComponent implements OnInit {

  requestReload = false;

  constructor(
    private destroy$: DestroyRef,
    private user: UserStateService,
    private router: Router,
    private analytics: AnalyticsService,
    private route: ActivatedRoute,
    private release: ReleaseDetectService,
    private change: ChangeDetectorRef,
    private element: ElementRef,
    private dialog: DynamicDialogService,
    private scrollService: MainScrollerService
  ) {
    this.scrollService.setTarget(element.nativeElement);

    route.queryParams.subscribe((v) => {
      if (v.support) {
        const lookup = v.support.split(':');
        this.dialog.load(() => import('src/app/user-support/user-support.component')
          .then(s => s.UserSupportComponent), { data: { [lookup[0]]: lookup[1] } });
      }
    });

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      mergeMap(v => {
        let rt = this.route;
        while (rt && rt.firstChild) { rt = rt.firstChild; }
        return rt.data;
      })
    )
      .subscribe((data) => {
        document.title = `Pipio | ${data.title}`;
      });
  }

  ngOnInit(): void {
    const getId = (x: User | undefined): string => (x ? x.id : x) ?? '_';
    merge(this.user.user$, of(undefined))
      .pipe(
        distinctUntilChanged((a, b) => getId(a) === getId(b)),
        mergeMap(user => user ? this.user.balanceFull$.pipe(
          take(1),
          map(b => [user, b] as const)
        ) : of(user)),
        tap((setup) => setup ? this.analytics.setup(...setup) : undefined),
        takeUntilDestroyed(this.destroy$)
      ).subscribe();


    this.release.watch$.subscribe(({ current, next }) => {
      this.requestReload = true;
      this.change.detectChanges();
      console.log('New Version available', next, 'current', current);
    });
  }

  reload(): void {
    this.release.reloadApp();
  }
}
