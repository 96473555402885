import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { UserStateService } from './auth/user-state.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private userState: UserStateService) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((err: unknown) => {
        if (err instanceof HttpErrorResponse && err.status === 401 && !request.url.endsWith('/auth/self')) {
          this.userState.trackLoginRedirect();
          console.log('Got us an error', err);
          this.userState.logout();
        }
        return throwError(() => err);
      })
    );
  }
}