import { catchError, map, of } from 'rxjs';

import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot, RouterStateSnapshot, createUrlTreeFromSnapshot,

} from '@angular/router';

import { AuthService } from 'src/api-client/account';
import { env } from 'src/environments/environment';

export const unauthGuard = (route: ActivatedRouteSnapshot, routeState: RouterStateSnapshot) => {
  const [auth] = [inject(AuthService)];
  return auth.getSelf().pipe(
    catchError(() => of(null)),
    map(val => {
      const isMobileDevice = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(window.navigator.userAgent);
      if (isMobileDevice && !routeState.url.includes('mobile-landing')) {
        return createUrlTreeFromSnapshot(route, ['/', 'mobile-landing']);
      }
      if (val) {
        window.opener?.postMessage({ navigate: env.baseUrl });
        return createUrlTreeFromSnapshot(route, ['/']);
      } else {
        return;
      }
    })
  );
};